// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const getCultsPreviewImage = (model) => {
  let url = "";
  const images = model.illustrations;

  // if (model.illustrationImageUrl) {
  //   return model.illustrationImageUrl;
  // }

  if (images && images.length > 0) {
    const filteredImage = images.filter(
      (i) =>
        i.imageUrl &&
        !i.imageUrl.endsWith(".gif") &&
        !i.imageUrl.endsWith(".mp4")
    )[0]?.imageUrl;
    const firstImage = images[0]?.imageUrl;
    url = filteredImage ? filteredImage : firstImage ? firstImage : "";
  }
  return url;
};

const getThingiversePreviewImage = (model) => {
  let url = "";
  if (model.preview_image) {
    return model.preview_image;
  } else if (model.thumbnail) {
    return model.thumbnail;
  }
  return url;
};

const getPrintablesPreviewImage = (model) => {
  const baseUrl = "https://media.printables.com/";
  try {
    if (model?.image?.filePath) {
      const pathParts = model.image.filePath.split("/");
      let fileType = pathParts[pathParts.length - 1].split(".").pop();
      pathParts.splice(-1, 0, `thumbs/cover/320x240/${fileType}`);
      return proxyUrl + baseUrl + pathParts.join("/");
    } else {
      return "";
    }
  } catch (e) {
    console.log(e);
    return proxyUrl + baseUrl + model?.image?.filePath;
  }
};

const getMyMiniFactoryPreviewImage = (model) => {
  const getUrl = (obj) => {
    if (obj?.thumbnail?.url) {
      return obj.thumbnail.url;
    } else if (obj?.standard?.url) {
      return obj.standard.url;
    } else if (obj?.original?.url) {
      return obj.original.url;
    } else if (obj?.large?.url) {
      return obj.large.url;
    } else if (obj?.tiny?.url) {
      return obj.tiny.url;
    } else return "";
  };

  let url = "";
  if (model.images && model.images.length) {
    const primaryImage = model.images.filter((i) => i.is_primary)[0];
    if (primaryImage) {
      url = getUrl(primaryImage);
    } else {
      url = getUrl(model.images[0]);
    }
  }

  return url;
};

export const modelNormalizer = (model, agent) => {
  let normalObj = JSON.parse(JSON.stringify(model));
  if (!normalObj.goFinal_creator) {
    normalObj.goFinal_creator = { name: "No Name" };
  }

  if (agent === "thingiverse") {
    normalObj.goFinal_preview_image = getThingiversePreviewImage(model);
    normalObj.goFinal_fallback_image = getThingiversePreviewImage(model);
    normalObj.goFinal_like_count = model.like_count ?? "";
    // normalObj.goFinal_view_count = model.view_count;
    normalObj.goFinal_created_at = model.created_at ?? model.added ?? "";
    normalObj.goFinal_collect_count = model.collect_count ?? "";
    normalObj.goFinal_name = model.name ?? "";
    normalObj.goFinal_id = model.id ?? "";
    // normalObj.goFinal_images = model.images;
    normalObj.goFinal_url = model.public_url ?? "";
    normalObj.goFinal_shareId = model.id ?? "";
    normalObj.goFinal_canShare = Boolean(model.id);

    normalObj.goFinal_tags = Array.isArray(model.tags)
      ? model.tags.map((p) => p.tag)
      : [];

    if (!Boolean(normalObj?.goFinal_creator?.identifier)) {
      normalObj.goFinal_creator.name = model?.creator?.name ?? "";
      normalObj.goFinal_creator.url = model?.creator?.public_url ?? "";
      normalObj.goFinal_creator.id = model?.creator?.id ?? "";
      normalObj.goFinal_creator.thumbnail = model?.creator?.thumbnail ?? "";
      normalObj.goFinal_creator.identifier = model?.creator?.name ?? "";
    }

    normalObj.goFinal_agent = "thingiverse";
  } else if (agent === "cults3d") {
    normalObj.goFinal_preview_image = getCultsPreviewImage(model);
    normalObj.goFinal_fallback_image = getCultsPreviewImage(model);
    normalObj.goFinal_like_count = model.likesCount ?? "";
    normalObj.goFinal_view_count = model.viewsCount ?? "";
    normalObj.goFinal_tags = model?.category?.name
      ? [model?.category?.name]
      : [];

    // creator
    if (!Boolean(normalObj?.goFinal_creator?.identifier)) {
      normalObj.goFinal_creator.name = model?.creator?.nick ?? "No Name";
      normalObj.goFinal_creator.url = model?.creator?.url ?? "";
      normalObj.goFinal_creator.thumbnail = model?.creator?.imageUrl ?? "";
      normalObj.goFinal_creator.identifier = model?.creator?.nick ?? "";
    }

    normalObj.goFinal_created_at = model.publishedAt ?? "";
    normalObj.goFinal_description = model.description ?? "No Description";
    normalObj.goFinal_name = model.name ?? "";
    normalObj.goFinal_id = model.id ?? "";
    normalObj.goFinal_shareId = model.slug ?? "";
    normalObj.goFinal_canShare = Boolean(model.slug);
    normalObj.goFinal_url = model.url ?? "";
    normalObj.goFinal_images =
      model?.illustrations &&
      model?.illustrations.map((i) => {
        return { url: i.imageUrl };
      });
    normalObj.goFinal_agent = "cults3d";
  } else if (agent === "myminifactory") {
    const previewUrl = getMyMiniFactoryPreviewImage(model);

    normalObj.goFinal_preview_image = previewUrl ?? "";
    normalObj.goFinal_fallback_image = previewUrl ?? "";
    normalObj.goFinal_like_count = model.likes ?? "";
    normalObj.goFinal_view_count = model.views ?? "";
    normalObj.goFinal_tags = Array.isArray(model.tags) ? model.tags : [];

    // creator
    if (!Boolean(normalObj?.goFinal_creator?.identifier)) {
      normalObj.goFinal_creator.name = model?.designer?.username ?? "No Name";
      normalObj.goFinal_creator.url = model?.designer?.profile_url ?? "";
      normalObj.goFinal_creator.thumbnail = model?.designer?.avatar_url ?? "";
      normalObj.goFinal_creator.identifier = model?.designer?.username ?? "";
    }
    normalObj.goFinal_shareId = model.id ?? "";
    normalObj.goFinal_canShare = Boolean(model?.designer?.username);

    normalObj.goFinal_created_at = model.published_at ?? "";
    normalObj.goFinal_description = model.description ?? "No Description";
    normalObj.goFinal_name = model.name ?? "";
    normalObj.goFinal_id = model.id ?? "";
    normalObj.goFinal_url = model.url ?? "";
    normalObj.goFinal_images =
      model?.images &&
      model?.images?.map((i) => {
        return { url: i.original?.url };
      });
    normalObj.goFinal_agent = "myminifactory";
  } else if (agent === "sketchfab") {
    normalObj.creator = {};
    normalObj.creator.name = model.user.displayName ?? "";
    const previewUrl = model?.thumbnails?.images[0]?.url;
    normalObj.preview_image = previewUrl;
    normalObj.like_count = model.likeCount ?? "";
    normalObj.view_count = model.viewCount ?? "";
    normalObj.created_at = model.publishedAt ?? "";
    normalObj.name = model.name ?? "";
    normalObj.id = model.uid ?? "";
    normalObj.description = model.description ?? "No Description";
    normalObj.images = model.thumbnails.images.map((i) => {
      return { url: i.url };
    }) ?? [{ url: null }];
    normalObj.agent = "sketchfab";
  } else if (agent === "printables") {
    // creator
    if (!Boolean(normalObj?.goFinal_creator?.identifier)) {
      normalObj.goFinal_creator.name = model?.user?.publicUsername ?? "";
      normalObj.goFinal_creator.identifier = model?.user?.id ?? "";
      normalObj.goFinal_creator.url =
        "https://www.printables.com/@" + model?.user?.handle ?? "";

      normalObj.goFinal_creator.thumbnail =
        proxyUrl +
          "https://media.printables.com/" +
          model?.user?.avatarFilePath ?? "";
    }
    const previewUrl = getPrintablesPreviewImage(model);
    normalObj.goFinal_preview_image = previewUrl;
    normalObj.goFinal_fallback_image =
      proxyUrl + "https://media.printables.com/" + model?.image?.filePath;
    normalObj.goFinal_like_count = model.likesCount ?? "";
    normalObj.goFinal_view_count = model.viewCount ?? ""; //TODO
    normalObj.goFinal_download_count = model.downloadCount ?? ""; //TODO
    normalObj.goFinal_created_at = model.datePublished ?? "";
    normalObj.goFinal_name = model.name ?? "";
    normalObj.goFinal_id = model.id ?? "";
    normalObj.goFinal_description = model.description ?? "No Description"; //TODO
    normalObj.goFinal_images = [{ url: previewUrl }]; //TODO
    normalObj.goFinal_url =
      "https://www.printables.com/model/" + model.id + "-" + model.slug;
    normalObj.goFinal_agent = "printables";
    normalObj.goFinal_shareId = model.id ?? "";
    normalObj.goFinal_canShare = Boolean(model.id);
    normalObj.goFinal_tags = Array.isArray(model?.category?.path)
      ? model?.category?.path.map((c) => c.name)
      : [];
  } else if (agent === "makerworld") {
    // creator
    if (!Boolean(normalObj?.goFinal_creator?.identifier)) {
      normalObj.goFinal_creator.name = model?.designCreator?.name ?? "";
      normalObj.goFinal_creator.identifier = model?.designCreator?.uid ?? "";
      normalObj.goFinal_creator.url =
        "https://www.makerworld.com/en/u/" + model?.designCreator?.uid ?? "";

      normalObj.goFinal_creator.thumbnail =
        model?.designCreator?.avatar +
          "?image_process=resize,w_400/format,webp" ?? "";
    }
    const fullQuality =
      model?.cover + "?image_process=resize,w_400/format,webp";
    normalObj.goFinal_preview_image = fullQuality
      ? fullQuality
      : model?.coverUrl ?? "";
    normalObj.goFinal_fallback_image = fullQuality;
    normalObj.goFinal_like_count = model.likeCount ?? "";
    // normalObj.goFinal_view_count = model.viewCount ?? ""; //TODO
    normalObj.goFinal_download_count = model.downloadCount ?? ""; //TODO
    normalObj.goFinal_created_at = model.createTime ?? "";
    normalObj.goFinal_name = model.title ?? "";
    normalObj.goFinal_id = model.id ?? "";
    normalObj.goFinal_description = model.description ?? ""; //TODO
    normalObj.goFinal_images = [{ url: fullQuality }]; //TODO
    normalObj.goFinal_url = "https://www.makerworld.com/en/models/" + model.id;
    normalObj.goFinal_agent = "makerworld";
    normalObj.goFinal_shareId = model.id ?? "";
    normalObj.goFinal_canShare = Boolean(model.id);
    normalObj.goFinal_tags = Array.isArray(model?.tags) ? model?.tags : [];
  }
  return normalObj;
};

export const commentNormalizer = (comment, agent) => {
  let normalObj = JSON.parse(JSON.stringify(comment));
  normalObj.goFinal_creator = { name: "No Name" };

  if (agent === "thingiverse") {
    normalObj.goFinal_creator.name = comment?.user?.name ?? "No Name";
    normalObj.goFinal_imageUrl = comment?.user?.thumbnail ?? "";
    normalObj.goFinal_text = comment?.body ?? "";
    normalObj.goFinal_images = comment?.attachments
      ? comment.attachments.map((i) => i.download_url)
      : [];
  } else if (agent === "cults3d") {
    normalObj.goFinal_creator.name = comment?.creator?.nick ?? "No Name";
    normalObj.goFinal_imageUrl = comment?.creator?.imageUrl ?? "";
    normalObj.goFinal_text = comment?.text ?? "";
    normalObj.goFinal_images = [];
  } else if (agent === "myminifactory") {
    normalObj.goFinal_creator.name = comment?.user?.name ?? "No Name";
    normalObj.goFinal_imageUrl =
      comment?.user?.avatar_small_thumbnail_url ?? "";
    normalObj.goFinal_text = comment?.comment ?? "";
    normalObj.goFinal_images = comment?.attachments.map(
      (i) => i?.standard?.url
    );
  } else if (agent === "printables") {
    normalObj.goFinal_creator.name =
      comment?.author?.publicUsername ?? "No Name";
    normalObj.goFinal_imageUrl =
      "https://media.printables.com/" + comment?.author?.avatarFilePath ?? "";
    normalObj.goFinal_text = comment?.text ?? "";
    normalObj.goFinal_images = comment?.images
      ? comment?.images.map(
          (i) => proxyUrl + "https://media.printables.com/" + i.filePath
        )
      : [];
  } else if (agent === "makerworld") {
    const key = comment.type === 1 ? "comment" : "ratingItem";
    const userKey = comment.type === 1 ? "user" : "creator";
    try {
      normalObj.goFinal_creator.name = comment[key][userKey]?.name ?? "No Name";
      normalObj.goFinal_imageUrl =
        comment[key][userKey]?.avatar +
          "?image_process=resize,w_400/format,webp" ?? "";
      normalObj.goFinal_text = comment[key]?.content ?? "";
      normalObj.goFinal_images = comment[key]?.images
        ? comment[key]?.images
        : [];
    } catch (e) {
      normalObj.goFinal_creator.name = "No Name";
      normalObj.goFinal_imageUrl = "";
      normalObj.goFinal_text = "";
      normalObj.goFinal_images = [];
    }
  }

  return normalObj;
};
